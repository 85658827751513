import React, { Fragment, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS, fontWeights, radius, space } from 'theme'
import { VIDEO_DYNAMIC } from 'consts'
import { linkTransformer } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import { Text } from 'components/atoms/Typography'
import Tooltip from 'components/molecules/Tooltip'
import {
  PALETTE_TYPE_GRADIENT,
  PALETTE_TYPE_COLOR,
  PALETTE_TYPE_MEDIA,
  THEME_DARK,
  TWITTER_COLORS,
} from 'routes/Calendar/consts'

const StyledDynamicMainWrapper = styled(Flex)`
  font-size: ${({ fontSize }) => fontSize}px;
`

const StyledDynamicImageWrapper = styled(Flex)`
  border-radius: ${radius.l};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${({ backgroundImage }) => backgroundImage && `background-image:${backgroundImage}`};
  ${({ backgroundColor }) => backgroundColor && `background-color:${backgroundColor}`};
  overflow: hidden;
  cursor: ${({ hasAudio }) => (hasAudio ? 'pointer' : 'initial')};
`

const StyledDynamicPostWrapper = styled(Flex)`
  font-size: ${({ fontSize }) => fontSize}em;
  box-shadow: rgb(145 147 169 / 85%) 0px 0px ${({ boxShadow }) => boxShadow}em;
  overflow: hidden;
  background: ${({ background }) => background};
`

const StyledMediaText = styled(Text)`
  box-sizing: border-box;
  white-space: break-spaces;
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledLogoWrapper = styled(Flex)`
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: ${radius.l};
  background: ${({ background }) => background};
`

const getDynamicImageColorsByTheme = ({ theme, transparency = 0 }) => {
  const transparency_updated = transparency ? 1 - transparency : 1
  let theme_color = `linear-gradient(91.01deg, rgba(255, 255, 255, ${transparency_updated}) 12.02%, rgba(255, 255, 255, ${transparency_updated}) 100%)`
  let profile_text_color = COLOR_CONSTANTS.NAVY
  let media_text_color = COLOR_CONSTANTS.DENIM
  let watermark_background =
    'linear-gradient(91.01deg, rgba(255, 255, 255, 0.83) 12.02%, rgba(255, 255, 255, 0.6) 100%)'
  let watermark_vista_color = COLOR_CONSTANTS.DENIM
  let watermark_social_color = COLOR_CONSTANTS.COBALT

  if (theme === THEME_DARK) {
    theme_color = `linear-gradient(91.01deg, rgba(0, 0, 0, ${transparency_updated}) 12.02%, rgba(0, 0, 0, ${transparency_updated}) 100%)`
    profile_text_color = 'rgba(255, 255, 255, 0.7)'
    media_text_color = COLOR_CONSTANTS.WHITE
    watermark_background = 'linear-gradient(91.01deg, rgba(0, 0, 0, 0.6) 12.02%, rgba(0, 0, 0, 0.3) 100%)'
    watermark_vista_color = COLOR_CONSTANTS.WHITE
    watermark_social_color = COLOR_CONSTANTS.WHITE
  }

  return {
    theme_color,
    profile_text_color,
    media_text_color,
    watermark_background,
    watermark_vista_color,
    watermark_social_color,
  }
}

const DynamicMedia = ({
  media,
  profiles = [],
  post,
  isSmall = false,
  isScreenshot = false,
  showDynamicIcon = false,
  verticalPreview = false,
}) => {
  const audioPlayerRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const {
    width,
    height,
    aspect_ratio,
    type,
    dynamic: {
      post_caption,
      text,
      show_profile,
      background: { background_type, background_color, background_media } = {},
      theme,
      content_width,
      scale,
      corner_radius,
      shadow,
      transparency,
      watermark,
      audio,
    } = {},
  } = media

  const { audio_gid, audio_root_entity_gid } = audio || {}

  let audioUrl = ''
  if (audio_gid) {
    audioUrl = `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/media/${audio_root_entity_gid}/${audio_gid}.mp3`
  }

  let mediaText = text || ''

  if (post_caption) {
    const { postText = '', customizations } = post || {}

    mediaText = postText || ''

    if (profiles && profiles.length === 1 && profiles[0]) {
      const { code, network, id, profileId } = profiles[0]

      const code_network = code || network
      const profile_gid = id || profileId

      if (customizations && customizations[code_network]) {
        const profileCustomizations = customizations[code_network][profile_gid] || {}

        mediaText = profileCustomizations.message || postText || ''
      }
    }
  }

  let backgroundColor
  let backgroundImage

  if (background_type === PALETTE_TYPE_GRADIENT) {
    backgroundImage = background_color
  } else if (background_type === PALETTE_TYPE_COLOR) {
    backgroundColor = background_color
  } else if (background_type === PALETTE_TYPE_MEDIA && background_media) {
    const { url, thumbnail_url, extension } = background_media

    let mediaUrl = url
    if (isSmall && extension !== 'gif') {
      mediaUrl = thumbnail_url
    }

    backgroundImage = `url(${mediaUrl})`
  }

  let name = 'Profile'
  let username = '@username'
  let picture_url = '/assets/avatar.svg'
  let defaultProfile = true

  if (profiles && profiles.length > 0) {
    if (profiles.length > 1) {
      name = 'Multiple profiles'
      username = ''
    } else if (profiles[0]) {
      // eslint-disable-next-line prefer-destructuring
      name = profiles[0].name || profiles[0].profileName
      username = profiles[0].username || profiles[0].profileUsername
      picture_url = profiles[0].picture_url || profiles[0].profilePictureUrl
      defaultProfile = false
    }
  }

  const {
    theme_color,
    profile_text_color,
    media_text_color,
    watermark_background,
    watermark_vista_color,
    watermark_social_color,
  } = getDynamicImageColorsByTheme({ theme, transparency })

  let RATIO_DIV_STYLE = {}

  if (!isScreenshot) {
    if (aspect_ratio === 0 || aspect_ratio >= 1) {
      RATIO_DIV_STYLE = { paddingTop: `${aspect_ratio === 0 ? 100 : 100 / aspect_ratio}%`, width: '100%' }
    } else if (aspect_ratio < 1) {
      if (verticalPreview) {
        RATIO_DIV_STYLE = { paddingTop: `${100 / aspect_ratio}%`, paddingLeft: `100%`, width: 0 }
      } else {
        RATIO_DIV_STYLE = { paddingTop: '100%', paddingLeft: `${aspect_ratio * 100}%`, width: 0 }
      }
    }
  } else {
    RATIO_DIV_STYLE = {
      width,
      height,
    }
  }

  let scale_const = 0.7685264737027886 * (aspect_ratio > 1 ? 1 : aspect_ratio)

  if (isSmall) {
    scale_const = 0.1875 * (aspect_ratio > 1 ? 1 : aspect_ratio)
  } else if (isScreenshot) {
    scale_const = aspect_ratio > 1 ? 1.59375 : 1.75
  }

  return (
    <StyledDynamicMainWrapper
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      fontSize={16 * scale_const}
      borderRadius={radius.l}
    >
      <Box height="0" position="relative" style={RATIO_DIV_STYLE} borderRadius={radius.l}>
        <Box height="100%" width="100%" left="0" top="0" position="absolute" borderRadius={radius.l}>
          <StyledDynamicImageWrapper
            alignItems="center"
            justifyContent="center"
            maxWidth={width}
            maxHeight={height}
            backgroundImage={backgroundImage}
            backgroundColor={backgroundColor}
            width="100%"
            height="100%"
            position="relative"
            onClick={() => {
              if (!isSmall && audioUrl) {
                if (audioPlayerRef && audioPlayerRef.current) {
                  setIsPlaying(!isPlaying)
                  const { paused } = audioPlayerRef.current
                  if (paused) {
                    audioPlayerRef.current.play()
                  } else {
                    audioPlayerRef.current.pause()
                  }
                }
              }
            }}
            hasAudio={audioUrl}
          >
            <StyledDynamicPostWrapper
              p={`${20 * scale_const}px`}
              width={`${content_width}%`}
              flexDirection="column"
              background={theme_color}
              fontSize={scale}
              borderRadius={`${corner_radius}em`}
              boxShadow={shadow}
            >
              {show_profile && (
                <Tooltip
                  wrapperComp={
                    <Flex alignItems="center" mb={`${12 * scale_const}px`}>
                      <ImageWithFallback
                        source={picture_url || '/assets/avatar.svg'}
                        fallbackSource="/assets/avatar.svg"
                        width="2.2em"
                        height="2.2em"
                        borderRadius={radius.pill}
                        removeStyle={isScreenshot}
                      />
                      <Flex mx={`${8 * scale_const}px`} flexDirection="column" flex="1">
                        {name && (
                          <Text
                            fontSize={`${0.95}em`}
                            color={profile_text_color}
                            fontWeight="medium"
                            textAlign="left"
                            fontStyle={defaultProfile ? 'italic' : 'normal'}
                          >
                            {name}
                          </Text>
                        )}

                        {username && (
                          <Text
                            fontSize={`${0.813}em`}
                            color={profile_text_color}
                            textAlign="left"
                            fontWeight="light"
                            fontStyle={defaultProfile ? 'italic' : 'normal'}
                          >
                            {username}
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  }
                  defaultTopOffset={-87}
                  message={
                    defaultProfile && !isSmall && !isScreenshot
                      ? `Profile name, username and picture will be inserted based on profile where this ${
                          type === VIDEO_DYNAMIC ? 'video' : 'image'
                        } is published`
                      : ''
                  }
                  isTriangleVisible={false}
                  left="50%"
                  right="50%"
                />
              )}
              {mediaText && (
                <StyledMediaText
                  fontSize={`${0.95}em`}
                  color={media_text_color}
                  textAlign="left"
                  dangerouslySetInnerHTML={{
                    __html: linkTransformer({
                      body: mediaText,
                      color: TWITTER_COLORS.HASHTAG,
                      fontWeight: fontWeights.normal,
                      isClickable: false,
                      transformHashstag: true,
                      hashtagColor: TWITTER_COLORS.HASHTAG,
                      hashtagFontWeight: fontWeights.normal,
                    }),
                  }}
                />
              )}
              {!isScreenshot && post_caption && !mediaText && (!profiles || profiles.length === 0) && (
                <StyledMediaText fontSize={`${0.95}em`} color={media_text_color} textAlign="left" fontStyle="italic">
                  This is a sample caption. It'll be replaced by your post's caption when you attach this dynamic media
                  to your post
                </StyledMediaText>
              )}
              {watermark && (
                <Flex flexDirection="column" mt={`${12 * scale_const}px`}>
                  <Flex height="1px" my={`${12 * scale_const}px`} bg="#B6CEF0" width="100%" />
                  <Flex py={`${6 * scale_const}px`} alignItems="center">
                    <Text fontSize={`${0.813}em`} color={profile_text_color} fontWeight="bold">
                      Made with
                    </Text>
                    <Image mx={`${6 * scale_const}px`} src="/assets/logo.svg" width="1.5em" height="1.5em" />
                    <Text as="span" fontSize={`${0.813}em`} color={watermark_vista_color} fontWeight="bold">
                      vista
                    </Text>
                    <Text as="span" fontSize={`${0.813}em`} color={watermark_social_color} fontWeight="bold">
                      social
                    </Text>
                  </Flex>
                </Flex>
              )}
            </StyledDynamicPostWrapper>

            {showDynamicIcon && (
              <Box position="absolute" top={space.xs} right={type === VIDEO_DYNAMIC ? space.l : space.xs}>
                <StyledPlayImage src="/assets/vistasocial/live.svg" />
              </Box>
            )}

            {type === VIDEO_DYNAMIC && !isScreenshot && (
              <Fragment>
                <Box position="absolute" top={space.xs} right={space.xs}>
                  <StyledPlayImage
                    src={isPlaying ? '/assets/vistasocial/pause.svg' : '/assets/vistasocial/instagram_play.svg'}
                  />
                </Box>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <audio
                  hidden
                  src={audioUrl}
                  preload="none"
                  ref={audioPlayerRef}
                  onEnded={() => {
                    if (isPlaying) {
                      setIsPlaying(false)
                    }
                  }}
                />
              </Fragment>
            )}
          </StyledDynamicImageWrapper>
        </Box>
      </Box>
    </StyledDynamicMainWrapper>
  )
}

DynamicMedia.defaultProps = {
  profiles: null,
  post: null,
  isSmall: false,
  isScreenshot: false,
  showDynamicIcon: false,
  verticalPreview: false,
}

DynamicMedia.propTypes = {
  media: PropTypes.object.isRequired,
  profiles: PropTypes.array,
  post: PropTypes.object,
  isSmall: PropTypes.bool,
  isScreenshot: PropTypes.bool,
  showDynamicIcon: PropTypes.bool,
  verticalPreview: PropTypes.bool,
}

export default DynamicMedia
